.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h1, h2 {
  text-align: center;
}

table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 80%;
}

th, td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.date-picker-container .react-datepicker-wrapper {
  margin: 0 10px;
}

/*.chart-container {*/
/*  width: 100%;*/
/*  max-width: 800px; !* Set a maximum width for the charts *!*/
/*  margin: 0 auto;*/
/*}*/
.chart-container {
  width: 100%;
  max-width: 1000px; /* Increase the maximum width for the charts */
  margin: 0 auto;
}

.chart-content {
  display: flex;
  align-items: center;
}

.chart-description {
  flex: 1;
  padding-right: 20px;
}

.chart-wrapper {
  flex: 3;
  position: relative;
  height: 400px;
}
